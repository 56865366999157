import React, { useEffect, useState } from 'react';
//import { Link } from "react-router-dom"
import Axios from "axios"
import InfiniteScroll from 'react-infinite-scroll-component';
import DashboardHeader from "./DashboardHeader"
import DashboardFooter from "./DashboardFooter"
import loader_img from "../../assets/images/loader_img.gif"
import { getAuth } from "firebase/auth";
import ApiFetchingData from '../ApiFetchingData';
import { kFormatter } from '../utils';
//import LoadingDotsIcon from "../LoadingDotsIcon"
//import Traffic from './Traffic';

import chartImg1 from '../../assets/images/chart-1.png'
import chartImg2 from '../../assets/images/chart-2.png'
import chartImg3 from '../../assets/images/chart-3.png'
//import chartImg4 from '../../assets/images/chart-4.png'

import UserPayoutList from './UserPayoutList';


function Dashboard(props) {
    const [isLoading, setIsLoading] = useState(true)
    const [payoutList, setPayoutList] = useState('')
    const [payoutStats, setPayoutStats] = useState('')
    const pageSize = process.env.REACT_APP_PAGESIZE
    const [pageOffset, setPageOffset] = useState(pageSize);
    const [noMore, setnoMore] = useState(true)
    const [isFirstLoad, setIsFirstLoad] = useState(true)


    const auth = getAuth();
    localStorage.setItem('userAuth', auth.currentUser.accessToken);
    

    useEffect(() => {
        //console.log('ffff')
        const ourRequest = Axios.CancelToken.source()
        const payoutRequestUrl = process.env.REACT_APP_BACKENDURL + 'getPayoutReadyRequests';
        const payoutReadyLists = async () => {
            //setCheckingInProgress(true)    
            setIsLoading(true);

            Axios.defaults.headers = {
                Authorization: 'Bearer ' + localStorage.getItem('userAuth')
            }

            try {
                const response = await Axios.post(payoutRequestUrl, {
                    data: {
                        "pageOffset": "0",
                        "pageSize": pageSize
                    }
                },
                    { cancelToken: ourRequest.token });
                const json = await response.data.result;
                if (json.status === 200) {
                    setPayoutList(json.payouts);
                    setIsLoading(false)
                }
                setIsLoading(false)
                //console.log(json.payouts);
            } catch (er) {
                if (er.response.status === 401) {
                    localStorage.clear();
                    window.location.href = process.env.REACT_APP_HOMEURL + 'login'
                }
                setIsLoading(false)
                //setCheckingInProgress(false)
                console.log("There was a problem or the request was cancelled.")
            }

        }

        const stasRequest = Axios.CancelToken.source()
        const getPayoutStatstUrl = process.env.REACT_APP_BACKENDURL + 'getPayoutStats';
        const getPayoutStats = async () => {
            //setCheckingInProgress(true)    
            //setIsLoading(true);

            Axios.defaults.headers = {
                Authorization: 'Bearer ' + localStorage.getItem('userAuth')
            }

            try {
                const response = await Axios.post(getPayoutStatstUrl, {
                    data: {}
                },
                    { cancelToken: stasRequest.token });
                const json = await response.data.result;
                //console.log(json.data);
                if (json.status === 200) {
                    setPayoutStats(json.data);

                    //setIsLoading(false)
                }
            } catch (er) {
                if (er.response.status === 401) {
                    localStorage.clear();
                    window.location.href = process.env.REACT_APP_HOMEURL + 'login'
                }
                //setIsLoading(false)
                //setCheckingInProgress(false)
                console.log("There was a problem or the request was cancelled.")
            }

        }
        getPayoutStats();
        payoutReadyLists();

    }, [pageSize])

    const fetchMoreData = async () => {
        const payoutRequestUrl = process.env.REACT_APP_BACKENDURL + 'getPayoutReadyRequests';
        const ourRequest = Axios.CancelToken.source();
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('userAuth')
        }
        setIsFirstLoad(false)
        try {
            const response = await Axios.post(payoutRequestUrl, {
                data: {
                    "pageOffset": pageOffset,
                    "pageSize": pageSize
                },
            },
                { cancelToken: ourRequest.token }
            );
            const json = await response.data.result;
            setIsFirstLoad(true)
            return json.payouts;
        } catch (e) {
            setIsFirstLoad(true)
            console.log("There was a problem or the request was cancelled." + e)
            //setIsLoading(false);
        }
    }

    const fetchData = async () => {

        const payoutListsLoadMore = await fetchMoreData();
        //console.log(payoutListsLoadMore);
        if (payoutListsLoadMore) {
            setPayoutList((payoutList) => [...payoutList, ...payoutListsLoadMore]);
            if (payoutListsLoadMore.length === 0 || payoutListsLoadMore.length < Number(pageSize)) {
                setnoMore(false);
            }
            setPageOffset(payoutList.length + Number(pageSize))
        }
    }

    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
            <DashboardHeader></DashboardHeader>
            <div className="body flex-grow-1 px-3">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-sm-6 col-lg-4">
                            <div className="card mb-4 text-white bg-primary">
                                <div className="card-body pb-0 d-flex justify-content-between align-items-start">
                                    <div>
                                        <div className="fs-4 fw-semibold">${payoutStats.pendingAmount > 0 ? kFormatter(payoutStats.pendingAmount) : 0} <span className="fs-6 fw-normal"></span></div>
                                        <div>Total Payout Requested</div>
                                    </div>
                                </div>
                                <div className="c-chart-wrapper mt-3 mx-3" style={{ height: "70px!important" }}>
                                    <img className="chart" id="card-chart1" width="100%" height="70" src={chartImg1} alt="chart" />

                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-4">
                            <div className="card mb-4 text-white bg-info">
                                <div className="card-body pb-0 d-flex justify-content-between align-items-start">
                                    <div>
                                        <div className="fs-4 fw-semibold">${payoutStats.paidAmount > 0 ? kFormatter(payoutStats.paidAmount) : 0} <span className="fs-6 fw-normal"></span></div>
                                        <div>Paid out by Learnee</div>
                                    </div>
                                </div>
                                <div className="c-chart-wrapper mt-3 mx-3" style={{ height: "70px!important" }}>
                                    <img className="chart" id="card-chart2" width="100%" height="70" src={chartImg2} alt="chart" />
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-4">
                            <div className="card mb-4 text-white bg-warning">
                                <div className="card-body pb-0 d-flex justify-content-between align-items-start">
                                    <div>
                                        {payoutStats.pendingAmount &&
                                            <div className="fs-4 fw-semibold">${kFormatter(payoutStats.inTransitAmount)} <span className="fs-6 fw-normal"></span></div>
                                        }
                                        <div>In Transit Amount</div>
                                    </div>
                                </div>
                                <div className="c-chart-wrapper mt-3" style={{ height: "70px!important" }}>
                                    <img className="chart" id="card-chart3" width="100%" height="70" src={chartImg3} alt="chart" />
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-sm-6 col-lg-3">
                            <div className="card mb-4 text-white bg-danger">
                                <div className="card-body pb-0 d-flex justify-content-between align-items-start">
                                    <div>
                                        <div className="fs-4 fw-semibold">44K <span className="fs-6 fw-normal"></span></div>
                                        <div>Sessions</div>
                                    </div>                                    
                                </div>
                                <div className="c-chart-wrapper mt-3 mx-3" style={{ height: "70px" }}>
                                    <img className="chart" id="card-chart4" width="100%" height="70" src={chartImg4} alt="chart" />
                                </div>
                            </div>
                        </div> */}

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-header">Requested Payouts</div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {isLoading &&
                                            <div className="text-center"><img alt="" src={loader_img} /></div>

                                        }

                                        {typeof payoutList !== 'undefined' &&
                                            <InfiniteScroll
                                                dataLength={payoutList.length}
                                                next={fetchData}
                                                hasMore={noMore}
                                                loader={!isFirstLoad && <ApiFetchingData />}
                                            >

                                                {payoutList &&
                                                    <UserPayoutList
                                                        data={payoutList} />
                                                }

                                                {!payoutList && !isLoading &&
                                                    <div className="text-center margin-top-10">No Requested Payouts!</div>
                                                }
                                            </InfiniteScroll>

                                        }
                                        {/* {payoutList &&
                                            <UserPayoutList
                                                data={payoutList} />
                                        } */}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <DashboardFooter></DashboardFooter>
        </div>

    );
}

export default Dashboard;
