import React, { useState, useEffect, useRef } from 'react';
import Axios from "axios"
import { Button } from "react-bootstrap";
//import { useParams } from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component';
import DashboardHeader from "./DashboardHeader"
import DashboardFooter from "./DashboardFooter"
import MarketplaceList from "./MarketplaceList"
import ApiFetchingData from '../ApiFetchingData';
import LoadingDotsIcon from "../LoadingDotsIcon"
import ExportModal from "./ExportModal";  // Import the modal component



function ArchivedMarketPlaceListsAdmin() {
    const [search_query, setSearchQuery] = useState('');

    const [marketplaceList, setmarketplaceList] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const pageSize = process.env.REACT_APP_PAGESIZE
    const [pageOffset, setPageOffset] = useState(pageSize);
    const [noMore, setnoMore] = useState(true)
    const [, setIsFirstLoad] = useState(true)
    const [isButtonDisabled, setButtonDisabled] = useState(true);

    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    const marketplaceRequestUrl = process.env.REACT_APP_BACKENDURL + 'fetchArchivedMarketplaceDots';

    const ourRequest = Axios.CancelToken.source()
    const marketplaceLists = async () => {
        console.log('===Hello===')
        //setCheckingInProgress(true)    
        setIsLoading(true);
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('userAuth')
        }

        try {
            const response = await Axios.post(marketplaceRequestUrl, {
                data: {
                    "query": "",
                    "pageOffset": 0,
                    "pageSize": pageSize,
                    "sortBy": "createdAt"
                }
            },
                { cancelToken: ourRequest.token });

            const json = await response.data.result;

            if (json.status === 200) {
                setPageOffset(pageSize);
                //setmarketplaceList('');
                //console.log(setmarketplaceList);
                setIsLoading(false);
                setmarketplaceList(json.dots);
            }
            setIsLoading(false);

        } catch (er) {
            setIsLoading(false);
            if (er.response.status === 401) {
                localStorage.clear();
                window.location.href = process.env.REACT_APP_HOMEURL + 'login'
            }
            //setCheckingInProgress(false)
            console.log("There was a problem or the request was cancelled.")
        }

    }

    const fetchDataRef = useRef(marketplaceLists);

    useEffect(() => {

        fetchDataRef.current();

    }, [])


    const fetchMoreData = async () => {
        const ourRequest = Axios.CancelToken.source();
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('userAuth')
        }
        setIsFirstLoad(false)
        var requestURL = marketplaceRequestUrl;


        var requestData = {
            "query": "",
            "pageOffset": pageOffset,
            "pageSize": pageSize,
            "sortBy": "createdAt"
        }

        if (search_query !== '') {
            requestURL = marketplaceRequestUrl;//Request URL changed in case of search
            requestData = {
                "query": search_query,
                "pageOffset": pageOffset,
                "pageSize": pageSize,
                "sortBy": "createdAt"
            }
        }

        console.log(requestData);

        try {
            const response = await Axios.post(requestURL, {
                data: requestData,
            },
                { cancelToken: ourRequest.token }
            );

            const json = await response.data.result;
            setIsFirstLoad(true)
            return json.dots;
        } catch (e) {
            setIsFirstLoad(true)
            console.log("There was a problem or the request was cancelled." + e)
            //setIsLoading(false);
        }
    }

    const fetchData = async () => {

        const marketplaceLoadMore = await fetchMoreData();
        console.log("marketplaceLoadMore===");
        if (marketplaceLoadMore) {
            setmarketplaceList((marketplaceList) => [...marketplaceList, ...marketplaceLoadMore]);
            if (marketplaceLoadMore.length === 0 || marketplaceLoadMore.length < Number(pageSize)) {
                setnoMore(false);
            }
            setPageOffset(marketplaceList.length + Number(pageSize))
        }
    }


    const fetchSearchedData = async () => {
        //setCheckingInProgress(true)    
        setIsLoading(true);
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('userAuth')
        }
        const filterRequest = Axios.CancelToken.source();
        setIsFirstLoad(false)
        try {
            const response = await Axios.post(marketplaceRequestUrl, {
                data: {

                    "query": search_query,
                    "pageOffset": 0,
                    "pageSize": pageSize,
                    "sortBy": "createdAt"
                }
            },
                { cancelToken: filterRequest.token });
            const json = await response.data.result;
            if (json.status === 200) {
                setPageOffset(pageSize);
                setmarketplaceList(json.dots);
            }

            if (json.status === 404) {
                setmarketplaceList('');
            }
            setIsLoading(false);
            //console.log(setmarketplaceList);
        } catch (er) {
            if (er.response.status === 401) {
                localStorage.clear();
                window.location.href = process.env.REACT_APP_HOMEURL + 'login'
            }
            //setCheckingInProgress(false)
            console.log("There was a problem or the request was cancelled.")
        }

    }

    function handleSearchInput(e) {
        e.preventDefault();
        const value = e.target.value
        if (value.length > 0) {
            setButtonDisabled(false);
        } else {
            //fetchData();
            setButtonDisabled(true);
        }
        //console.log(value);
        setSearchQuery(value)
    }

    function submitSearchData(e) {
        e.preventDefault();
        //setPageOffset(0);
        const trimmedValue = search_query.trim();
        console.log(trimmedValue.length);
        if (trimmedValue.length > 0) {
            fetchSearchedData();
        } else {
            //fetchFilteredData();
            setnoMore(true);
        }
        //console.log(search_q);
    }

    function resetSearchData(e) {
        e.preventDefault();
        setSearchQuery('');
        setnoMore(true);
        //setPageOffset(0);       
        //console.log("marketplaceList===",marketplaceList);
        marketplaceLists();

    }

    const handleRemoveArchivedItem = (itemToRemove) => {
        console.log("itemToRemove", itemToRemove)
        // Filter out the removed item and update the list
        const updatedItems = marketplaceList.filter((item) => item.mktPlaceId !== itemToRemove);
        setmarketplaceList(updatedItems);
    };

    // Handle the modal export completion
    const handleExportComplete = () => {
        setShowModal(false); // Close the modal after export is done
    };



    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light userlist">
            <DashboardHeader></DashboardHeader>
            <div className='container-fluid'>

                <div className="dropdown header-nav d-md-flex" style={{ float: "left" }}>

                    {/* Export Button */}
                    <div className="ms-3 float-right">
                        <Button variant="primary" onClick={() => setShowModal(true)}>
                            Export CSV
                        </Button>
                    </div>

                </div>

                <div className='ms-3 float-right'>
                    <form className='me-xxl-3 admin-search'>
                        <div className="input-group">
                            <input className="form-control py-2 border-right-0 border searchBox" placeholder="Search via Marketplace Id" onChange={handleSearchInput} type="text" id="srch-term" value={search_query} />
                        </div>
                        <span className="margin-left-10 width-100">
                            <button className={"btn btn-primary border-left-0 " + (isButtonDisabled ? 'disabled' : '')} type="submit" onClick={submitSearchData}>
                                <i className="fa fa-search"></i>
                            </button>

                            <button className={"btn btn-primary border-left-0 margin-left-10"} type="submit" onClick={resetSearchData}>
                                <i className="fa fa-refresh" aria-hidden="true"></i>
                            </button>

                        </span>
                    </form>
                </div>


                {/* Use the ExportModal component */}
                <ExportModal
                    show={showModal}
                    onHide={() => setShowModal(false)}  // Close modal when clicked on close button
                    onExport={handleExportComplete}  // Notify when export is complete
                    marketplaceList={marketplaceList}
                    isArchived = {true}
                />

            </div>
            <div className="card-body">
                <div className="table-responsive dotslisting">

                    {isLoading &&
                        <LoadingDotsIcon />
                    }



                    {typeof marketplaceList !== 'undefined' &&
                        <InfiniteScroll
                            dataLength={marketplaceList.length}
                            next={fetchData}
                            hasMore={noMore}
                            loader={marketplaceList.length > 20 && <ApiFetchingData />}
                        >

                            {marketplaceList &&
                                <MarketplaceList
                                    data={marketplaceList} isArchived={true} onRemoveItem={handleRemoveArchivedItem} />
                            }

                            {!marketplaceList && !isLoading &&
                                <div className="text-center margin-top-10">No Result!</div>
                            }

                        </InfiniteScroll>
                    }



                </div>
            </div>
            <DashboardFooter></DashboardFooter>
        </div>
    );
}

export default ArchivedMarketPlaceListsAdmin;