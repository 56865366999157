import React, { useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import NavBar from './NavBar'
import DotsCarousel from "./DotsCarousel";
import Axios from 'axios';

import '../assets/css/home_new.css';
import '../assets/css/home.css';
import '../assets/css/animation.css'
import '../assets/css/landingpage.css';
import '../assets/css/download.css';
import LandingPageFooter from './LandingPageFooter';
//import DotsImagesMobileCarousel from './BlurredImages';



import thumbnail_page_5_img_1 from '../assets/images/landing-page/1st.png';
import thumbnail_page_5_img_2 from '../assets/images/landing-page/2nd.png';
import thumbnail_page_5_img_3 from '../assets/images/landing-page/3rd.png';
import thumbnail_page_5_img_4 from '../assets/images/landing-page/4th.png';
import thumbnail_page_5_img_5 from '../assets/images/landing-page/5th.png';
import thumbnail_page_5_img_6 from '../assets/images/landing-page/6th.png';

import steps_arrow from '../assets/images/landing-page/steps_arrow.png';
import app_ss1 from '../assets/images/landing-page/app_ss1.png';

import Binoculars from '../assets/images/landing-page/Binoculars.png';
import MessageBot from '../assets/images/landing-page/MessageBot.png';
import EuroMoney from '../assets/images/landing-page/EuroMoney.png';
import arrow_right from '../assets/images/landing-page/arrow-right.png';

import loader_img from '../assets/images/loader_img.gif';




import TextSlider from './TextSlider';

// App Component
function DownloadsPage() {

    const [dotsData, setDotsData] = useState([]); // State for dots
    const [loading, setLoading] = useState(true); // Loading state
    const [selectedCategory, setSelectedCategory] = useState(""); // Currently selected category

    const App_url = 'https://learnee.page.link/rniX'

    const categories = [
        { name: "Health and Nutrition", hideOnMobile: false },
        { name: "Food & Recipes", hideOnMobile: false },
        { name: "Travel & Tourism", hideOnMobile: true },
        { name: "Education", hideOnMobile: false },
        { name: "Cultural and Religious", hideOnMobile: true },
        { name: "Entertainment", hideOnMobile: true },
        { name: "Productivity", hideOnMobile: false },
        { name: "Lifestyle", hideOnMobile: false },
        { name: "Health and Wellness", hideOnMobile: true },
        { name: "Self-improvement", hideOnMobile: false },
    ];


    const generateAskVaSiDotsWebUrl = process.env.REACT_APP_BACKENDURL + 'askVaSiPublicApi';

    const defaultPayload = {
        pageSize: 10,
        sortBy: "views",
        sortOrder: "desc",
        categories: "",
        isRecommended: false,
        query: "",
        pageOffset: 0,
        request_type: 'dots_categories'
    };

    // Fetch dots by category
    const fetchDots = async (category) => {
        setLoading(true);
        try {

            const payload = { ...defaultPayload, categories: category || "" }; // Add category filter
            const response = await Axios.post(
                generateAskVaSiDotsWebUrl,
                {
                    data: payload,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            //console.log("dotsData==", response.data.result.dots)

            setDotsData(response.data.result?.dots || []); // Update dots data


        } catch (error) {
            console.error("Error fetching dots:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch default dots on initial render
    useEffect(() => {
        fetchDots(); // No category initially
    }, []);

    const handleCategoryClick = (category) => {
        if (selectedCategory === category) return;
        setSelectedCategory(category); // Update selected category
        handleButtonClick(category, "Explore Category")
        fetchDots(category); // Fetch dots based on selected category
    };

    const dotsWithViewAll = [
        ...dotsData,
        { isViewAll: true }, // Add "View All" as the last card
    ];

    useEffect(() => {
        const navbar = document.querySelector('.navbar');
        if (navbar) {
            navbar.classList.remove('hidden');
        }
    }, []);


    const handleButtonClick = (action, label) => {
        // Track the button click event
        ReactGA.event({
            category: "Button",
            action: action, //"Download Now",
            label: label //"VaSi App Link",
        });
    };



    // Array of words to replace "iPhone"
    const words = ['iPhone', 'Trip', 'Outfit', 'Gadget', 'Kicks'];
    const interval = 3000;
    const duration = 2000;


    return (
        <div className="askvasi-home landing-page download-page" id='askvasi-landing'>
            {/* Navbar */}
            <NavBar />

            {/* Page 6 and beyond */}
            <div className="page-wrapper slide-up-overlay-slide-6 page-6 active" id="page-6">
                {/* Container for the two columns */}
                <div className="hero-section-container">
                    {/* Left Column: Overlay content */}
                    <div className="overlay-content-hero-section">

                        <div className='hero-title'><span className='hero-title-1'>Your next <TextSlider texts={words} interval={interval} duration={duration} /></span>
                            {/* <span className={`fade-in-title fade ${fadeIn ? 'in' : 'out'}`}>{words[currentWordIndex]}</span> */}
                            <br></br>is on us, Just share
                            <br></br>your Wisdom</div>
                        <p>Share all your tips & tricks with us and earn!</p>
                        <a rel="noopener noreferrer" href={App_url}>
                            <button  onClick={() => handleButtonClick("Download Now", "VaSi App Link")} className="cta-button">Download Now</button></a>
                    </div>

                    {/* <DotsImagesMobileCarousel /> */}


                    {/* Right Column: Blurred images container */}
                    <div className="blurred-images-container-hero-section">
                        <div className="blurred-image image-1">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/gnZddAtLfTu7ARBj8"><img src={thumbnail_page_5_img_3} loading="lazy" alt="Blurred Dots 3" /></a>
                        </div>
                        <div className="blurred-image image-2">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/GJe2WwtFNcHkCJM16"><img src={thumbnail_page_5_img_2} loading="lazy" alt="Blurred Dots 2" /></a>
                        </div>
                        <div className="blurred-image image-3">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/EMda9rS37HdzxGaz8"><img src={thumbnail_page_5_img_1} loading="lazy" alt="Blurred Dots 1" /></a>
                        </div>

                        <div className="blurred-image image-4">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/KiX3nHr7xamYw3qD6"><img src={thumbnail_page_5_img_4} loading="lazy" alt="Blurred Dots 4" /></a>
                        </div>
                        <div className="blurred-image image-5">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/iyXHC1Yapi9A33vq7"><img src={thumbnail_page_5_img_5} loading="lazy" alt="Blurred Dots 5" /></a>
                        </div>
                        <div className="blurred-image image-6">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/o8sKaBqYFHyT3zk8A"><img src={thumbnail_page_5_img_6} loading="lazy" alt="Blurred Dots 6" /></a>
                        </div>
                    </div>

                </div>
            </div>
            {/* Steps Section */}
            <div className="how-it-works-section">
                <div className="section-title">Your Side Hustle Starts Here!</div>
                <div className="steps-container">
                    <div className="step">
                        <div className="step-circle"><span className='step-number'>1</span></div>
                        <h3>Create a Dot</h3>
                        <p>Dots are bite sized written pieces on your experiences, insights and preferences</p>
                    </div>

                    <div className="step-arrow-container">
                        <img src={steps_arrow} alt="Arrow" className="step-arrow" />
                        <img src={arrow_right} alt="Arrow" className="step-arrow-mobile" />
                    </div>


                    <div className="step">
                        <div className="step-circle margin-20"><span className='step-number'>2</span></div>
                        <h3>Post and Earn</h3>
                        <p>Earn from every reader that consumes your Dot</p>
                    </div>

                    <div className="step-arrow-container">
                        <img src={steps_arrow} alt="Arrow" className="step-arrow" />
                        <img src={arrow_right} alt="Arrow" className="step-arrow-mobile" />
                    </div>

                    <div className="step">
                        <div className="step-circle margin-20"><span className='step-number'>3</span></div>
                        <h3>Start Earning</h3>
                        <p>Get paid for your Dot on every purchase</p>
                    </div>
                </div>
            </div>


            <div className="beyond-chat-section">
                <div className="content-container">
                    <div className="image-container">
                        <div className='footer_bg_left'></div>
                        <img loading="lazy"
                            src={app_ss1}
                            alt="VaSi App Preview"
                            className="beyond-chat-image"
                        />
                        <div className='footer_bg_right'></div>
                    </div>
                    <div className="text-container">
                        <div className="beyond-title">How VaSi works for you?</div>
                        <p>
                            A platform to turn your experiences, life lessons and expertise into income. All you have to do, is begin...
                        </p>
                        <ul className="custom-list">
                            <li><img width={38} src={Binoculars} alt="Binoculars" /> <strong>Built for Creators:</strong> Turn your ideas into shareable, actionable knowledge effortlessly with VaSi.</li>
                            <li><img width={38} src={MessageBot} alt="Binoculars" /> <strong>Monetize Your Expertise:</strong> A global audience awaits to pay for a piece of your mind.</li>
                            <li><img width={38} src={EuroMoney} alt="Binoculars" /> <strong>Real Insights, Real Connections:</strong> Consume Dots shared by people from all walks of life and watch your life transform.</li>
                        </ul>
                        <a rel="noopener noreferrer" href={App_url}>
                            <button onClick={() => handleButtonClick("Get Started", "VaSi App Link")} className="cta-button">Get Started</button>
                        </a>
                    </div>
                </div>
            </div>


            <div className="explore-dots-section">
                <div className="section-title">Ideas. Inspiration. Insights</div>
                <p className="section-subtitle">
                    Explore a wide range of categories and take inspiration from other creators.
                </p>
                <div className="category-buttons">
                    {categories.map((category, index) => (
                        <button
                            key={index}
                            onClick={() => handleCategoryClick(category.name)}
                            className={`${category.hideOnMobile ? "hide-category-mobile" : ""
                                } ${selectedCategory === category.name ? "active" : ""}`}
                        >
                            {category.name}
                        </button>
                    ))}
                </div>

                {loading &&
                    <div className="carousel-loader-container">
                        <img
                            src={loader_img}
                            alt="Loading..."
                            className="carousel-loader"
                        />
                    </div>
                }
                <div className="carousel-container">
                    {/* Import and use the DotsCarousel */}
                    <DotsCarousel dotsData={dotsWithViewAll} />
                    <div className="view-all">
                        <a rel="noopener noreferrer" href={App_url}>
                            <button onClick={() => handleButtonClick("Explore More", "VaSi App Link")} className='cta-button'>Explore More</button>
                        </a>
                    </div>
                </div>


            </div>


            <LandingPageFooter />

        </div>



    );
}
export default DownloadsPage;