import React from "react";
import { Modal, Button } from "react-bootstrap";

const ActionModal = ({ item, index, handlers, state, show, onClose }) => {
    const {
        toggleDisable,
        togglePopularity,
        toggleFeatured,
        togglePromoted,
        generateDynamicLink,
        copyToClipboard,
    } = handlers;
    const {
        isButtonDisabledDots,
        isButtonDisabledPopular,
        isButtonDisabledFeatured,
        isButtonDisabledPromoted,
        isButtonDisabledDynamicLink,
        dynamicLinks,
    } = state;

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Actions for {item.mktPlaceId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button
                    className={`w-100 mb-3  ${isButtonDisabledDots[index] ? "loader-btn" : ""}`}
                    variant={`${item.isActive ? "danger" : "primary"}`}
                    onClick={() => toggleDisable(item.mktPlaceId, !item.isActive, index)}
                >
                    {item.isActive ? "Disable Dots" : "Enable Dots"}
                </Button>

                {item.isActive && (
                    <>
                        <Button
                            className={`w-100 mb-3 ${isButtonDisabledPopular[index] ? "loader-btn" : ""}`}
                            variant= {`${item.isPopular ? "danger" : "primary"}`}
                            onClick={() => togglePopularity(item.mktPlaceId, !item.isPopular, index)}
                        >
                            {item.isPopular ? "Remove Popular" : "Set as Popular"}
                        </Button>

                        <Button
                            className={`w-100 mb-3 ${isButtonDisabledFeatured[index] ? "loader-btn" : ""}`}
                            variant={`${item.isFeatured ? "danger" : "primary"}`}
                            onClick={() => toggleFeatured(item.mktPlaceId, !item.isFeatured, index)}
                        >
                            {item.isFeatured ? "Remove Featured" : "Set as Featured"}
                        </Button>

                        {item.price > 0 && (
                            <Button
                                className={`w-100 mb-3  ${isButtonDisabledPromoted[index] ? "loader-btn" : ""}`}
                                variant={`${item.isPromoted ? "danger" : "primary"}`}
                                onClick={() => togglePromoted(item.mktPlaceId, !item.isPromoted, index)}
                            >
                                {item.isPromoted ? "Remove Promoted Dot" : "Promote Dot"}
                            </Button>
                        )}
                    </>
                )}



                {dynamicLinks[index] ? (
                    <Button
                        className="w-100 mb-3"
                        variant="success"
                        onClick={() => copyToClipboard(dynamicLinks[index])}
                    >
                        Copy Link
                    </Button>
                ) : (
                    <Button
                        className={`w-100 mb-3 ${isButtonDisabledDynamicLink[index] ? "loader-btn" : ""}`}
                        variant="primary"
                        onClick={() => generateDynamicLink(item.mktPlaceId, index)}
                    >
                        Generate Link
                    </Button>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ActionModal;
