import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Axios from 'axios';

const ExportModal = ({ show, onHide, onExport, marketplaceList, isArchived }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10); // Default page size
    const marketplaceRequestUrl = process.env.REACT_APP_BACKENDURL + 'initiateMarketplaceSearchAdmin';

    // Reset form fields when modal is closed
    useEffect(() => {
        if (!show) {
            setStartDate('');
            setEndDate('');
        }
    }, [show]);

    const handlePageSizeChange = (e) => {
        const value = e.target.value;
        if (value >= 1) { // Ensure positive number only
            setPageSize(value);
        }
    };

    // Handle exporting the data as CSV
    const handleExport = async () => {
        if (!startDate || !endDate) {
            alert('Please select both start and end dates.');
            return;
        }

        if (startDate > endDate) {
            alert('Start date cannot be later than end date');
            return;
        }


        setLoading(true);

        try {
            // const response = await axios.get(marketplaceRequestUrl, {
            //     params: {
            //         startDate: startDate,
            //         endDate: endDate,
            //     },
            //     responseType: 'blob',
            // });

            const startDateUnix = convertToUnixTimestamp(startDate);
            const endDateUnix = convertToUnixTimestamp(endDate);

            

            const response = await Axios.post(marketplaceRequestUrl, {
                data: {
                    "query": "",
                    "pageOffset": 0,
                    "pageSize": parseInt(pageSize),
                    "sortBy": "createdAt",
                    // "sortOrder": "desc",
                    "isPopular": "",
                    "startDate": startDateUnix,
                    "endDate": endDateUnix,
                    "request_type": "export_csv",
                    ...(typeof isArchived !== 'undefined' && { isArchived })
                }
            });

            //const json = await response.data.result;

            if (response.status === 200) {

                const { fileName, fileContent } = response.data.result;

                // Decode the base64 file content to a Blob
                const blob = new Blob([new Uint8Array(atob(fileContent).split("").map(c => c.charCodeAt(0)))], { type: 'text/csv' });

                // Create a download link for the file
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(link.href);

                // Close the modal
                onHide();
            }else{
                alert('No dots found for the selected date range.');
            }
        } catch (error) {
            console.error("Error exporting CSV:", error);
        } finally {
            setLoading(false);
        }
    };

    const convertToUnixTimestamp = (dateString) => {
        // Convert the string date to a Date object
        const dateObj = new Date(dateString);

        // Check if the date is invalid
        if (isNaN(dateObj.getTime())) {
            console.error("Invalid date format");
            return null;
        }

        // Get the timestamp in milliseconds
        const timestamp = dateObj.getTime();

        // Convert milliseconds to seconds (Unix timestamp in seconds)
        return Math.floor(timestamp);
    };

    // Function to convert JSON to CSV
    // const convertToCSV = (jsonData) => {
    //     const header = Object.keys(jsonData[0]).join(',');
    //     const rows = jsonData.map(item => Object.values(item).join(','));
    //     return `${header}\n${rows.join('\n')}`;
    // };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Export MarketPlace Dots</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    <label>Start Date:</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="form-control"
                    />
                </div>

                <div className="mb-3">
                    <label>End Date:</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="form-control"
                    />
                </div>

                <div className="mb-3">
                    <label>Page Size:</label>
                    <input
                        type="number"
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        className="form-control"
                        min="1"  // Ensure at least 1 record per page
                    />
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleExport}
                    disabled={isLoading || !startDate || !endDate}
                >
                    {isLoading ? 'Exporting...' : 'Export CSV'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ExportModal;
