import React, { useEffect, useState } from 'react'
import Axios from "axios"
import { useParams, useNavigate, Link } from "react-router-dom"
import Page from "../Page"
import LoadingDotsIcon from "../LoadingDotsIcon"
import NotFoundAdmin from "./NotFoundAdmin"
import DashboardHeader from "./DashboardHeader"
import DashboardFooter from "./DashboardFooter"

import { formatAMPM, freeDots } from "../utils"
import { Rating } from 'react-simple-star-rating'
import defaultAvatar from "../../assets/images/mystery-man.jpg"
import backArrow from "../../assets/images/back-arrow.png"
//import addToCartBlue from "../assets/images/addToCartBlue.png"


const AdminDetailPage = (props) => {
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [post, setPost] = useState(null)
    //const [showOverlay, setOverlay] = useState(true)
    //const [showAttachment, setShowAttachment] = useState(true)
    let navigate = useNavigate();
    localStorage.removeItem("redirectionUrl")
    const isApp = localStorage.getItem('a');
    //console.log(isApp);
    const [imageStatus, setIsLoadingImage] = useState(true)
    const handleImageLoaded = () => {
        setIsLoadingImage(false)
    }
    const [isButtonDisabledDots, setButtonDisabledDots] = useState('');
    const updateMarketplace_URL = process.env.REACT_APP_BACKENDURL + 'manageMarketPlaceDotsViaAdmin';

    useEffect(() => {
        const URL = process.env.REACT_APP_BACKENDURL + 'fetchMarketPlaceDotForId';
        const ourRequest = Axios.CancelToken.source()
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('userAuth')
        }
        const fetchPost = async () => {
            try {
                const response = await Axios.post(URL, {
                    data: {
                        "mktPlace_id": id,
                        "isAdmin": true
                    }
                },
                    { cancelToken: ourRequest.token });
                const json = await response.data;
                setPost(json.result.marketPlaceDot);
                //console.log(json.result.marketPlaceDot.payload.htmlDesc.length);
                setIsLoading(false);
            } catch (e) {
                console.log("There was a problem or the request was cancelled.")
                setIsLoading(false);
            }
        }

        fetchPost()

        return () => {
            ourRequest.cancel()
        }
    }, [id])

    //console.log(post.isActive);

    if (!isLoading && !post) {
        return <NotFoundAdmin />
    }

    if (isLoading)
        return (
            <Page title="...">
                <LoadingDotsIcon />
            </Page>
        )
    const profile_pic = ((post.payload.userAvatar === '' || post.payload.userAvatar === null) ? defaultAvatar : post.payload.userAvatar);
    const date = new Date(post.createdAt)
    const dateFormatted = formatAMPM(date);

    //const content = post.payload.htmlDesc;
    //if(post.payload.htmlDesc.length > 150){
    //const content = post.payload.htmlDesc.substring(0, post.payload.htmlDesc.length - 150);
    // }

    //const contents = content+'...';
    //console.log(navigate);
    const backtolist = (e) => {
        e.preventDefault();
        if (isApp === "true") {//|| typeof navigate("") === 'undefined'
            var HOMEURL = process.env.REACT_APP_HOMEURL;
            if (window.location.hostname === "localhost") {
                HOMEURL = process.env.REACT_APP_HOMEURLLOCALHOST;
            }
            //console.log(window.location.hostname);            
            window.location = HOMEURL
        } else {
            navigate(-1);
        }
        localStorage.removeItem("a");
    }

    const removetDostFromMarketplace = async (data) => {
        const shouldDelete = window.confirm('Are you sure you want to disable this Dots?');
        if (shouldDelete) {
            const ourRequest = Axios.CancelToken.source();
            console.log("data==", data)
            setButtonDisabledDots(true);
            try {
                const response = await Axios.post(updateMarketplace_URL, {
                    data: {
                        "marketPlaceId": data.mktPlaceId,
                        "isActive": false
                    }
                },
                    { cancelToken: ourRequest.token }
                );
                const json = await response.data;
                if (json.result.status === 200) {
                    const key = 'mktPlaceId';
                    const value = data.mktPlaceId;
                    // Store the key-value pair in local storage
                    localStorage.setItem(key, value);

                    data.isActive = false;
                    console.log("data1===", data)
                    setButtonDisabledDots(false);
                } else {
                    alert(json.result.message)
                    setButtonDisabledDots(false);
                }
            } catch (er) {

                setButtonDisabledDots(false);
                //console.log("There was a problem or the request was cancelled." + er)
            }
        }

        //console.log(showToast)

    }

    const activeDostFromMarketplace = async (data) => {
        const ourRequest = Axios.CancelToken.source();
        console.log("data==", data)
        setButtonDisabledDots(true);
        try {
            const response = await Axios.post(updateMarketplace_URL, {
                data: {
                    "marketPlaceId": data.mktPlaceId,
                    "isActive": true
                }
            },
                { cancelToken: ourRequest.token }
            );
            const json = await response.data;
            if (json.result.status === 200) {
                // Remove the key from local storage
                localStorage.removeItem('mktPlaceId');

                data.isActive = true;
                console.log("data1===", data)
                setButtonDisabledDots(false);
            } else {
                alert(json.result.message)
                setButtonDisabledDots(false);
            }
        } catch (er) {

            setButtonDisabledDots(false);
            //console.log("There was a problem or the request was cancelled." + er)
        }

        //console.log(showToast)

    }

    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light userlist">
            <DashboardHeader></DashboardHeader>
            <div className='container-fluid'>
                <div className="col-xl-8 margin-auto">
                    <div className="card padding-20 mb-4">
                        {post &&
                            <>
                                <div className="blog-header detail-page">
                                    <div className='clearfix'>
                                        <div className='bg-color-details'></div>
                                        <div className="pull-left">
                                            <span className='back-icon-details'>
                                                <img className='back-icon img-cursor-pointer' onClick={backtolist} alt="back" src={backArrow} />&nbsp;
                                                <h2 className='detail-title'>Dots Details</h2>
                                            </span>
                                            <span className=''>
                                                <img className="avatar-small rounded-circle dots-avtar-bg" alt="" src={profile_pic} />
                                            </span>
                                            <div className="flex-column pull-right margin-left-10">
                                                <h6 className="mb-0 font-weight-normal crator-color">{post.creator}</h6> <small className='font-weight-light'>{dateFormatted}</small>
                                            </div>

                                        </div>

                                        <div className='pull-right'>
                                            <div className="dropdown">
                                                {post.isActive === true ? (
                                                    <button onClick={() => removetDostFromMarketplace(post)} className={"btn p-2  btn-primary " + (isButtonDisabledDots ? 'loader-btn' : '')} type="button" data-coreui-toggle="dropdown" aria-haspopup="true" aria-expanded="false" alt="Remove Dots From Marketplace">
                                                        Disable Dots
                                                    </button>
                                                ) : (
                                                    <button onClick={() => activeDostFromMarketplace(post)} className={"btn p-2  btn-primary " + (isButtonDisabledDots ? 'loader-btn' : '')} type="button" data-coreui-toggle="dropdown" aria-haspopup="true" aria-expanded="false" alt="Remove Dots From Marketplace">
                                                        Enable Dots
                                                    </button>
                                                )
                                                }
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className='clearfix'>
                                    <div className='pull-left title-width'>
                                        <h4 className="blog-title">{post.dotTitle}</h4>
                                    </div>
                                    <div className='pull-right credit-info'>
                                    <i className="fa fa-dollar-sign" aria-hidden="true"></i><span className='font-weight-600'>&nbsp;{typeof post.price === 'undefined' ? 'Free' : freeDots(post.price)}&nbsp;</span>

                                    </div>
                                </div>
                                <div className='clearfix margin-bottom-10'>
                                    <div className='pull-left'>
                                        {post.tags && post.tags.length > 0 && post.tags.map((tag, index) => {
                                            return (
                                                <span className="tagItem btn-margin" key={index}> {tag}</span>
                                            )
                                        })
                                        }
                                    </div>
                                    <div className='pull-right dot-ratings'>
                                        <Rating initialValue={post.ratingHeadCount} size={15} fillColor={"#FFE153"} readonly={true} emptyColor={""} className={"react-simple-star-rating rating-details"} /> &nbsp;{post.ratingHeadCount}
                                    </div>
                                </div>
                            </>
                        }
                        {post &&
                            <div className="row margin-auto">
                                <div className="col-sm-12 blog-main">
                                    <div className="blog-post">
                                        <div className="para-perm desc" dangerouslySetInnerHTML={{ __html: post.payload.htmlDesc }} />
                                    </div>
                                </div>
                            </div>

                        }
                        <div className="row border-top">{/*m-1 padding-left-0 */}
                            <div className="card-group padding-left-0">
                                {post.payload.dotMedia && post.payload.dotMedia.length > 0 &&
                                    <h5 className='attachment-title'>Attachment</h5>
                                }
                            </div>
                        </div>
                        <div className="row margin-auto">
                            {post.payload.dotMedia && post.payload.dotMedia.length > 0 && post.payload.dotMedia.map((media, i) => {

                                if (media.type === 0 && media.url !== null) {
                                    return (
                                        <div className="col-md-12 mb-2" key={i}>
                                            <div className="thumbnail">
                                                {imageStatus &&
                                                    <div className="filler-div"></div>
                                                }
                                                <img width="100%" onLoad={handleImageLoaded.bind(this)} src={media.url} alt="" className="image-clickable" />
                                            </div>
                                        </div>
                                    )
                                } else if (media.type === 1 && media.url !== null) {
                                    return (
                                        <div className="col-md-12 mb-2" key={i}>
                                            <video controls width="100%" height="auto" poster={media.thumbUrl}>
                                                <source src={media.url} type="video/mp4" />
                                                Sorry, your browser doesn't support embedded videos.
                                            </video>
                                            {/*<ReactVideo
                                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                    // Disable right click
                                    onContextMenu={e => e.preventDefault()}
                                    src={media.url}
                                    poster={media.thumbUrl}
                                    primaryColor="red"
                                // other props
                                />*/}
                                        </div>
                                    )
                                } else if (media.type === 2 && media.url !== null) {
                                    return (
                                        <div className="col-md-12 mb-2" key={i}>
                                            <audio controls>
                                                <source src={media.url} type="audio/ogg"></source>
                                                <source src={media.url} type="audio/mpeg"></source>
                                                {/*fallback for non supporting browsers goes here*/}
                                                <p>Your browser does not support HTML5 audio, but you can still
                                                    <Link to={media.url}>download the music</Link>.</p>
                                            </audio>
                                            {/*<ReactAudioPlayer
                                            src={media.url}
                                            controls
                                        />*/}
                                        </div>
                                    )
                                } else if (media.type === 3 && media.url !== null) {
                                    return (
                                        <div className="col-md-12 mb-2" key={i}>
                                            <iframe
                                                src={media.url}
                                                title="Vasi"
                                                width="100%"
                                                height="800"
                                                loading="eager"
                                                allowFullScreen={true}
                                            ></iframe>
                                            {/*<DocumentViewer
                                            queryParams="hl=Nl"
                                            url={media.url}
                                            viewerUrl={media.thumbUrl}                                           
                                           >
                                       </DocumentViewer>*/}
                                        </div>
                                    )
                                } else if (media.type === 4) {
                                    return (
                                        ''
                                    )
                                } else {
                                    return (
                                        <div className="col-md-12 mb-2" key={i}>
                                            <div className="filler-div-audio filler-div-audio-details" style={{ backgroundColor: '#' + Math.random().toString(16).substr(-6) }}></div>
                                        </div>
                                    );
                                }


                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <DashboardFooter></DashboardFooter>
        </div>
    );
}

export default AdminDetailPage;